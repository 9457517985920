import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import * as ColorUtils from '../components/color-utils'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

const AboutItemLeft = ({ text, title, image, index }) => (
  <div className={'row about-item mb-5 mobile-wrap-reverse'}>
      <div className='col-lg-4'>
      <PreviewCompatibleImage 
        festClassName="about-image"
        imageInfo={image}
        alt={title ? title : ''} />
      </div>
      <div className='col-lg-1'></div>
      <div className='col-lg-7 about-item-info'>
        <div className='mb-sm-4'>
          {title ? <h2 className='mb-lg-5 mt-sm-5'>{title}</h2> : ''}
          <div className='text'>{text}</div>
        </div>
      </div>
    </div>
);

const AboutItemRight = ({ text, title, image }) => (
  <div className='row about-item mb-5'>
    <div className='col-lg-7 about-item-info'>
      <div className='mb-sm-4'>
        {title ? <h2 className='mb-lg-5 mt-sm-5'>{title}</h2> : ''}
        <div className='text'>{text}</div>
      </div>
    </div>
    <div className='col-lg-1'></div>
    <div className='col-lg-4'>
      <PreviewCompatibleImage 
        festClassName="about-image"
        imageInfo={image}
        alt={title ? title : ''} />
    </div>
  </div>
);

export const AboutPageTemplate = class extends React.Component {

  componentDidMount() {
    ColorUtils.setDarkColor();
  }

  render() {
    return (
      <section className='container-xxl container-padding'>
        {this.props.data.aboutItems.map((item, index) => {
          return index % 2 === 0 ? <AboutItemLeft index={index} text={item.description} title={item.title} image={item.image} key={index}/> :
            <AboutItemRight text={item.description} title={item.title} image={item.image} key={index}/>;
        })}
      </section>
    );
  }
}
AboutPageTemplate.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

const AboutPage = ({data}) => {
  const { frontmatter } = data.markdownRemark;
	return (
		<Layout pageTitle='Fest • About'>
			<AboutPageTemplate data={frontmatter}/>
		</Layout>
	)
};

AboutPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default AboutPage

export const pageQuery = graphql`
  query AboutPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
      frontmatter {
        title
        aboutItems {
          title
          image {
            childImageSharp {
              gatsbyImageData(
                placeholder: NONE
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          description
        }
      }
    }
  }
`;
