  // --fest-dark-green: #304F4A
  // --fest-light-green: #D8FFA4
  // --fest-beige: #FFFBE7

export function setDarkColor() {
  let root = document.documentElement;
  root.style.setProperty('--fest-bg-color', '#304F4A');
  root.style.setProperty('--fest-color', '#FFFBE7');
  root.style.setProperty('--fest-title-color', '#D8FFA4');
  root.style.setProperty('--fest-logo-color', '#FFFBE7');
}

export function setLightColor() {
  let root = document.documentElement;
  root.style.setProperty('--fest-bg-color', '#D8FFA4');
  root.style.setProperty('--fest-color', '#304F4A');
  root.style.setProperty('--fest-title-color', '#304F4A');
  root.style.setProperty('--fest-logo-color', '#304F4A');
}


export function setBeigeColor() {
  let root = document.documentElement;
  root.style.setProperty('--fest-bg-color', '#FFFBE7');
  root.style.setProperty('--fest-color', '#304F4A');
  root.style.setProperty('--fest-title-color', '#304F4A');
  root.style.setProperty('--fest-logo-color', '#304F4A');
}