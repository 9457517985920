import * as React from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const PreviewCompatibleImage = ({ imageInfo, festClassName, alt }) => {
  const gatsbyImageLoaded = getImage(imageInfo);

  if (!!gatsbyImageLoaded) {
    return (
      <GatsbyImage className={festClassName} image={gatsbyImageLoaded} alt={alt} placeholder="none" backgroundColor="#304f4a8c" />
    );
  } else if (imageInfo) { // for Netlify CMS 
    return <img className={festClassName} src={imageInfo} alt={alt} />;
  } else {
    return null
  }
};

PreviewCompatibleImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    style: PropTypes.object,
  }).isRequired,
};

export default PreviewCompatibleImage;
